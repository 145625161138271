import React from "react";

function Language() {
  return (
    <div>
      <div className="max-w-7xl m-auto px-8 md:pt-20 pt-10">
        <div>
          <h1 className="text-base font-bold"> Vonage Patents</h1>
          <h4 className="text-base italic">
            Local or Geographic Number & Toll Free Number
          </h4>
          <ul className="list-disc ml-5">
            <li>U.S. Pat. No. 7,386,111</li>
            <li>U.S. Pat. No. 7,680,262</li>
            <li>U.S. Pat. No. 8,213,594</li>
            <li>U.S. RE48760</li>
          </ul>
          <h4 className="text-base italic">
            Vonage Business Click to Call Me & Click to Dial
          </h4>
          <ul className="list-disc ml-5">
            <li>U.S. Pat. No. 8,320,543</li>
            <li>U.S. Pat. No. 8,588,389</li>
            <li>U.S. Pat. No. 8,683,044</li>
            <li>U.S. Pat. No. 9,319,440</li>
          </ul>
          <h4 className="text-base italic">Contact Center Optimization</h4>
          <ul className="list-disc ml-5">
            <li>U.S. Pat. No. 9,609,135</li>
            <li>U.S. Pat. No. 10,931,724</li>
          </ul>
          <h4 className="text-base italic">
            Call Continuity & System Architecture
          </h4>
          <ul className="list-disc ml-5">
            <li>U.S. Pat. No. 9,516,067</li>
            <li>U.S. Pat. No. 9,521,029</li>
            <li>U.S. Pat. No. 9,521,049</li>
            <li>U.S. Pat. No. 9,647,943</li>
            <li>U.S. Pat. No. 9,647,956</li>
            <li>U.S. Pat. No. 9,667,802</li>
            <li>U.S. Pat. No. 9,729,347</li>
            <li>U.S. Pat. No. 10,601,773</li>
          </ul>

          <h4 className="text-base italic">
            Integrations with 3rd Party Applications
          </h4>

          <ul className="list-disc ml-5">
            <li>U.S. Pat. No. 10,079,932 (G-Suite)</li>
            <li>U.S. Pat. No. 10,244,115 (Bullhorn)</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Language;
