import React from "react";
import Cards from "./Cards";
import Header from "./Header";
import Patentscontent from "./Patentscontent";
import Speak from "./../../components/Speak";
import data from "./../../data.json";
function Index({location}) {
  return (
    <div>
      <Header />
      <Patentscontent />
      <Cards card3={data.card3} />
      <Speak  location={location}/>
    </div>
  );
}

export default Index;
